import gql from 'graphql-tag';
import Vue, { VNode } from 'vue';
import { ApolloError } from 'apollo-boost';
import { VueTelInput } from 'vue-tel-input';

import './NewClient.css';
import Button from '@/components/Admin/Button/Button';
import apollo from '@/services/apollo';

const initialFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
};

const NewClient = Vue.extend({
  data() {
    return {
      formData: { ...initialFormValues },
      formError: '',
      loading: false,
      phoneInputProps: {
        defaultCountry: 'FR',
        disabled: true,
        mode: 'international',
        placeholder: 'Client phone number',
      },
    };
  },

  methods: {
    handleAddNewClient(event: Event) {
      event.preventDefault();

      this.formError = '';
      this.loading = true;

      const { formData } = this;

      const mutation = gql`
        mutation addClient(
          $lastName: String!,
          $firstName: String!,
          $email: String!,
          $phone: [String!]!
        ){
          addClient(
            email: $email
            lastName: $lastName
            firstName: $firstName
            phone: $phone
          ) {
            id
          }
        }
      `;

      apollo.mutate({
        mutation,
        variables: {
          ...formData,
          phone: [formData.phone.replace(/\s+/g, '')],
        },
      })
        .then(({ data }) => {
          this.$emit('reloadClientList');

          // Reset form
          this.formData = { ...initialFormValues };

          const clientId = data?.addClient?.id;
          this.$router.push(`/admin/clients/${clientId}`);
        })
        .catch((err: ApolloError) => {
          this.formError = err.graphQLErrors.map(({ message }) => message).join(' ');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updatePhoneNumber(event: any) {
      this.formData.phone = event.formattedNumber;
    },
  },

  name: 'new-client',

  render(): VNode {
    // Funny type bug forces the props to be added twice
    const telProps = {
      defaultCountry: 'FR',
      disabled: true,
      mode: 'international',
      placeholder: 'Client phone number',
      required: true,
    };

    return (
      <div class="new-client">
        <header class="dashboard-header">
          <h1 class="dashboard-header__title">New Client</h1>
        </header>

        <form onSubmit={this.handleAddNewClient} class="dashboard-content">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" name="firstName" v-model={this.formData.firstName} id="firstName" required />
          </div>

          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" name="lastName" v-model={this.formData.lastName} id="lastName" required />
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model={this.formData.email} name="email" required />
          </div>

          <div class="form-group">
            <VueTelInput
              v-model={this.formData.phone}
              mode='international'
              placeholder='Client phone number'
              required={true}
              {...telProps}
            />
          </div>

          {
            this.formError && (
              <div class="form-group">
                <div class="form-error">{this.formError}</div>
              </div>
            )
          }

          <div class="form-group">
            <Button loading={this.loading} primary type='submit'>Create Client</Button>
          </div>
        </form>
      </div>
    );
  },
});

export default NewClient;
